import React, { useState } from 'react';
import emailjs from 'emailjs-com';

export default () => {
    const [inputVals, setInputVals] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
        insurance: '',
        claimNum: '',
        signature: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        address: false,
        city: false,
        state: false,
        zip: false,
        email: false,
        phone: false,
        insurance: false,
        signature: false,
    });
    const [showCompletiton, setShowCompletion] = useState(false);
    const [missingLabel, setMissingLabel] = useState('');

    const textInput = ({id, label, placeholder}) => {
        const outerDivClass = error[id] ? 'errorDiv inputDiv' : 'inputDiv';
        return (
            <div className={outerDivClass}>
                <label className='inputLabel'>{label}</label>
                <input className= 'pdfInput' type="text" id={id} name={id} aria-required="true" aria-invalid="false" value={inputVals[id]} placeholder={placeholder} onChange={val => {
                    setInputVals({
                        ...inputVals,
                        [id]: val.target.value
                    })
                    if (error[id]) {
                        setMissingLabel('');
                        setError({
                            ...error,
                            [id]: false
                        });
                    }
                }} />
            </div>
        )
    }
    const outerDivClassAuthorize = error['authorize'] ? 'errorDiv inputDiv' : 'inputDiv';
    const outerDivClassFirstCheck = error['firstCheck'] ? 'errorDiv inputDiv' : 'inputDiv';
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy; 
    const submitFunc = event => {
        event.preventDefault();
        event.stopPropagation();
        let valsMissing = [];
        let errorIds = [];
        if (inputVals.firstName.length < 2) {
            valsMissing.push('first name');
            errorIds.push('firstName');
        }
        if (inputVals.lastName.length < 2) {
            valsMissing.push('last name');
            errorIds.push('lastName');
        }
        if (inputVals.address.length < 4) {
            errorIds.push('address');
            valsMissing.push('address');
        }
        if (inputVals.city.length < 4) {
            errorIds.push('city');
            valsMissing.push('city');
        }
        if (inputVals.state.length < 2) {
            errorIds.push('state');
            valsMissing.push('state');
        }
        if (inputVals.zip.length < 5) {
            errorIds.push('zip');
            valsMissing.push('zip code');
        }
        if (inputVals.email.length < 2 || !inputVals.email.includes('@') || inputVals.email.indexOf('@') >= inputVals.email.length - 2) {
            valsMissing.push('email address');
            errorIds.push('email');
        }
        if (inputVals.phone.split("-").join('').length < 10) {
            valsMissing.push('phone number');
            errorIds.push('phone');
        }
        
        if (inputVals.insurance.length < 4) {
            errorIds.push('insurance');
            valsMissing.push('insurance carrier');
        }
        if (!document.getElementById('authorize').checked) {
            errorIds.push('authorize');
            valsMissing.push('electronic authorization');
        }
        if (document.getElementById('firstCheck').value === '') {
            errorIds.push('firstCheck');
            valsMissing.push('first insurance check answer');
        }

        if (inputVals.signature.length < 4) {
            errorIds.push('signature');
            valsMissing.push('signature');
        }
        if (valsMissing.length > 0) {
            let newError = {
                ...error
            };
            for (let i = 0; i < errorIds.length; i++) {
                newError[errorIds[i]] = true;
            }
            setError(newError);
            let missingLabel = valsMissing[0];
            if (valsMissing.length == 2) {
                missingLabel = valsMissing.join(' and ');
            } else if (valsMissing.length > 2) {
                valsMissing[valsMissing.length - 1] = `and ${valsMissing[valsMissing.length - 1]}`
                missingLabel = valsMissing.join(', ');
            }
            setMissingLabel(missingLabel);
            return;
        }
        try { 
            emailjs.sendForm('service_crx8xo6', 'template_2a2z3u4', '#userAttributesForm', 'SO7dYRB9rFDhnpzfY')
                .then((result) => {
                    setShowCompletion(true)
                }, (error) => {
                    console.log('ERROR:', error.text);
                });
        } catch (err) {
            console.log(`ERROR 2: ${err}`);
        }
    };

    return (
        <form className='pdfContainerDiv' id='userAttributesForm' onSubmit={submitFunc}>
            {textInput({id:'firstName', label:'First Name (required)'})}
            {textInput({id:'lastName', label:'Last Name (required)'})}
            {textInput({id:'address', label:'Street address (required)'})}
            {textInput({id:'city', label:'City (required)'})}
            {textInput({id:'state', label:'State (required)'})}
            {textInput({id:'zip', label:'Zip code (required)'})}
            {textInput({id:'email', label:'Your Email (required)'})}
            {textInput({id:'phone', label:'Phone (required)'})}
            {textInput({id:'insurance', label:'Insurance Carrier (required)'})}
            {textInput({id:'claimNum', label:'Claim # (optional)'})}
            <input className= 'hidden' type="text" id='date' name='date' defaultValue={today} ></input>
            <div className={outerDivClassFirstCheck}>
                <label className='inputLabel' htmlFor='firstCheck'>Do you have the 1st check?</label>
                <select name="firstCheck" id="firstCheck" className='pdfSelect' onChange={() => {
                    if (error.firstCheck) {
                        setMissingLabel('');
                        setError({
                            ...error,
                            firstCheck: false
                        });
                    }
                }} >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
            <div id='consents'>
                <label>REPAIR AUTHORIZATION</label>- I have reviewed with The Painting Place and identified the damages to the vehicle described above (the “Vehicle”) and I hereby authorize and engage The Painting Place to repair all damage to the Vehicle, whether or not visible, including any additional repairs deemed necessary by The Painting Place upon disassembly and close inspection (the “Repairs”). I authorize The Painting Place to determine which parts of the vehicle need to be replaced or repaired as approved by the claims policy issuer and I hereby authorize The Painting Place to install replacement parts as needed to comply with applicable claims policy issuer requirements and guidelines which may include the use and installation of alternative parts including aftermarket, recycled, used, like-kind quality and/or reconditioned parts. I understand that new, OEM parts are the best and often times the most expensive option for my vehicle. I understand that I will pay the difference if I wish to use OEM parts versus what was written on the estimate.
                <br/>
                <br/>
                <label>VEHICLE OPERATION</label>- I hereby authorize The Painting Place’s employees to operate the Vehicle for the purposes of transporting it and for testing, inspection or as otherwise deemed necessary by The Painting Place.
                <br/>
                <br/>
                <label>DIRECTION TO PAY</label>- I represent and warrant that I am the owner, or the authorized agent of the owner, of the Vehicle. I acknowledge and agree that, before The Painting Place shall be obligated to deliver or release the Vehicle, (i) I agree to pay, or provide to the satisfaction of The Painting Place for the payment of, the entire cost of the Repairs and other amounts due hereunder and (ii) if all or any portion of the cost of the Repairs is to be paid by check or draft from an insurance company, I agree to obtain such check or draft from the insurer and deliver the same to The Painting Place, properly endorsed by all payees (including any lien holder, if required) for payment to The Painting Place. Additionally, I hereby agree that my insurance company may pay The Painting Place directly for completed repair work including payments for any supplemental repairs. The Painting Place will not accept a personal check in payment of Repairs without the valid Texas driver’s license of the maker. I agree that, if my personal check is dishonored or returned by my bank, I (i) will pay to The Painting Place a return fee of $30 and (ii) hereby authorize The Painting Place to electronically debit my account for the amount of such check plus the return fee.
                <br/>
                <br/>
                <label>POWER OF ATTORNEY</label>- In consideration for The Painting Place ‘s performance of the Repairs, I appoint The Painting Place and its authorized agents as my attorney- in-fact, with the power and authority, in my name and place, to (a) endorse any check or draft made payable to me in payment for damages to the Vehicle, (b) to sign any release in settlement of claims for such damage, in conjunction with or as a condition to receipt of the proceeds of such check or draft, and/or (c) to sign a vehicle title for the transfer of the Vehicle in the event the Vehicle is abandoned or is repossessed by The Painting Place in accordance with applicable law. I understand and agree that this power of attorney is coupled with an interest and may not be revoked until The Painting Place has received payment in full for the Repairs of the Vehicle. To secure payment in full for the Repairs and all other amounts due hereunder, I grant The Painting Place a security interest in, and acknowledge that The Painting Place shall have the rights granted under a statutory and/or constitutional lien upon, the Vehicle.
                <br/>
                <br/>
                <label>AUTHORIZATION AND ACCESS TO VEHICLE DATA</label>- I hereby provide consent to The Painting Place to extract and use data that is stored in the Vehicle, including in its event data recorder, at any time during the repair process including but not limited to prior to conducting any scanning or diagnostics operations on the Vehicle.
                <br/>
                <br/>
                <label>LIABILITY</label>-I agree that The Painting Place will not be liable for any loss or damage (i) to the Vehicle, or any components or personal items left in the Vehicle, as a result of fire, theft, disappearance, or other cause beyond its control, such as Acts of God including tornadoes, hail, wind damage, floods, earthquakes or other natural disasters or (ii) resulting from any delay in completion of the Repairs, whether due to disruptions in the supply or availability of labor, parts or materials, or other factors beyond The Painting Place’s control.
                <br/>
                <br/>
                <label>STORAGE</label>- I further agree that if I fail to pick up the Vehicle, or The Painting Place has not received payment in full under this contract, within 30 days following completion of the Repairs, The Painting Place shall have the right to charge a reasonable storage fee (of not less than $100 per day) until such time as it has received such payment in full and I have picked up the vehicle.
                <br/>
                <br/>
                <label>LEGAL ACTION AND FEES IF APPLICABLE</label>- In the event legal action is taken by The Painting Place to enforce this contract or its security interest and/or lien with respect to the Vehicle, Collin County, TX will be the applicable venue in addition to any other amounts due hereunder, I agree to pay the reasonable attorney’s fees, court costs and out-of-pocket expenses incurred by The Painting Place in connection with such action.
                <br/>
                <br/>
                <label>DISPUTE SETTLEMENT AND ARBITRATION</label>- I hereby agree that in the event of a dispute or controversy with The Painting Place regarding this authorization or any aspect of my repair that I shall first attempt to resolve the dispute by way of mediation. If the mediation process provides no resolution then I hereby agree to further arbitration governed by the appropriate applicable entity and shall be settled by arbitration in accordance with the Texas Arbitration Act in accordance with the laws of the State of Texas, in judgement upon the award rendered by the Arbitrator may be entered of record or any court having proper jurisdiction. The locale of arbitration will be in Collin County, TX. Furthermore, I agree that any arbitration shall be handled on an individual basis, in Collin County, TX, and waive any right or authority for arbitration by class action, in joinder with other The Painting Place customers or the general public.
                <br/>
                <br/>
                <label>TERMS AND CONDITIONS</label>- I have hereby read, fully understand and agree to all terms, conditions and authorizations herein to The Painting Place. I understand that a copy of these terms and conditions are available to me for my reference and records.
                <br/>
                <br/>
            </div>
            {!showCompletiton ? (
                <div>
                    <div className={outerDivClassAuthorize}>
                        <label className='inputLabel'>Electronic Authorization (required)</label>
                        <input className='checkbox' type="checkbox" id="authorize" onChange={() => {
                            if (error.authorize) {
                                setMissingLabel('');
                                setError({
                                    ...error,
                                    authorize: false
                                });
                            }
                        }} />
                        <label htmlFor="authorize"> I authorize The Painting Place to begin repairs on my vehicle.</label>
                    </div>
                    {textInput({id:'signature', label:'Electronic Signature (required)', placeholder:'Your name here'})}
                    <button className='sendButton'>Send</button>
                    {missingLabel.length > 0 &&
                        <h3 className='successh3'><span className='errorSpan'>ERROR</span>{`:  Please add your ${missingLabel}`}</h3>
                    }
                </div>
            ) : (
                <div>
                    <h2 className='successh2'>Success!</h2>
                    <h3 className='successh3'>Your repair authorization form has been sent to our team.  We'll be in contact with you to followup on your repair.</h3>
                </div>
            )}
        </form>
    )
};
