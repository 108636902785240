import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import mainImage from "images/company_pics/after_large.jpeg";
import upTree1 from "images/company_pics/up_in_tree.jpg";
import after from "images/company_pics/after4.jpg";
import brayan from "images/company_pics/brayan.jpg";
import treeLogoTop from "images/tree_logoTop.png";
import phoneIcon from "images/phone.png";

import Features from "components/features/DashedBorderSixFeatures";
import Footer from "components/footers/MiniCenteredFooter.js";


export default () => (
  <AnimationRevealPage>
    <header>
      <div className='logoDiv'>
        <img src={treeLogoTop} alt="logo" className='logoIcon'/>
        <h1>
          The Tree Musketeers
        </h1>
      </div>
      <div className='navLinks'>
        <img
          className='phoneIcon'
          src={phoneIcon}
        />
        <a href="tel:2143826986">(214) 382-6986</a>
      </div>
    </header>
    <section className='mainImgSection'>
      <img
        className='mainIMG'
        src={mainImage}
      />
    </section>
    <Features />
    <section className='aboutSection'>
      <h2 id='about' className='sectionHeader'>
        About us
      </h2>
      <h3>
        Our Team
      </h3>
      <p>
        Our team is comprised of full-time tree and landscaping professionals that have been working in the North Dallas area for years.  We are hard-working, and seek to do the best long-term solution for your yard/ tree situation.
      </p>
      <img
        className='upTree1'
        src={upTree1}
      />
      <h3>
        Service area
      </h3>
      <p>
        We serve the entire North Dallas area!  Richardson, Plano, Allen, McKinney, Garland, Fairview, The Colony, Frisco, Wylie, and Addison
      </p>
      <img
        className='after1'
        src={after}
      />
    </section>
    <section className='contactSection'>
      <h2 id='contact' className='sectionHeader'>
        Contact us
      </h2>
      <div className='contactDiv'>
        <img
          className='brayanImg'
          src={brayan}
        />
        <div className='contactInfoDiv'>
          <h3>
            Free Estimates
          </h3>
          <p>
            Brayan is the owner and manager of The Tree Muskeeters.  Call or text Brayan at <a href="tel:2143826986">(214) 382-6986</a> to set up a free estimate.
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </AnimationRevealPage>
);
